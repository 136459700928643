<template>
  <div>
    <img class="page-bg" :src="config.home" />
    <div v-if="config" class="page-container">
      <div class="title-box" style="opacity:0;">
        <img class="title-icon" src="@/assets/title.png" />
      </div>
      <div class="button-box">
        <img @click="startGame" class="button-icon" src="@/assets/home/start.png" />
        <img @click="showPopup('RuleTip')" class="button-icon" src="@/assets/home/rule.png" />
      </div>
    </div>
    <Logo></Logo>
    <Popup ref="popup" v-slot="{close}">
      <component @close="close" :is="popupName"></component>
    </Popup>
  </div>
</template>

<script type="text/javascript">
//组件
import Logo from "@/components/Logo.vue"
import Popup from "@/components/Popup.vue"
import LoginTip from "@/views/components/LoginTip.vue"
import RuleTip from "@/views/components/RuleTip.vue"

//store
import {mapState} from 'vuex'

export default {
  name: "Home",
  data() {
    return {
      popupName:"RuleTip",//要显示的弹窗的名称
    }
  },
  computed:{
    ...mapState(['config','userInfo']),
  },
  components: {Logo,Popup,LoginTip,RuleTip},
  created(){

  },
  mounted(){
    
  },
  methods:{
    startGame(){//开始抽奖
      if(this.userInfo){
        this.skipPage('lottery')
      }else{
        this.showPopup('LoginTip')
      }
    },
    skipPage(page){//跳转页面
      this.$router.push(page)
    },
    showPopup(name){//显示弹窗
      this.popupName = name
      this.$refs.popup.open()
    }
  }
}
</script>

<style scoped>
  .page-container,.page-bg{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 7.5rem;
    height: 15rem;
    object-fit: cover;
  }
  .title-box{
    padding-top: 1.8rem;
    width: 5.02rem;
    height: 1.83rem;
    margin: 0 auto 7.5rem;
  }
  .title-icon{
    width: 100%;
    height: 100%;
  }
  .button-icon{
    width: 3.86rem;
    height: 0.98rem;
    margin: 0.3rem auto 0;
  }
  .button-icon:first-of-type{
    margin-top: 0;
  }
</style>
